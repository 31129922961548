import axios from "axios";
import { BASE_URL_RELIANCE } from "./utilityService";
import { ApiServiceException } from "./ApiService";

class PublicApiService {
  #ax;

  constructor() {
    this.#ax = axios.create({
      baseURL: BASE_URL_RELIANCE,
      headers: { "Content-Type": "application/json" },
    });
  }

  async post(url, data) {
    try {
      const response = await this.#ax.post(url, data);
      return response.data;
    } catch (e) {
      console.error(e);
    }
  }

  async put(url, data) {
    try {
      const response = await this.#ax.put(url, data);
      return response.data;
    } catch (e) {
      console.error(e);
    }
  }

  async patch(url, data) {
    try {
      const response = await this.#ax.patch(url, data);
      return response.data;
    } catch (e) {
      console.error(e);
    }
  }

  async get(url, data) {
    try {
      const response = await this.#ax.get(`${url}`);
      return response.data;
    } catch (e) {
      console.error(e);
    }
  }
}

export default new PublicApiService();
