import * as _ from "lodash";
import moment from "moment";
import querystring from "querystring";
import {
  BASE_URL_RELIANCE,
  DOMAIN_COOKIE_NAME,
  getCookie,
  DAARA_COOKIE_NAME,
  DAARA_ENROLLEE_BASE_URL,
  DAARA_REFRESH_TOKEN,
} from "./utilityService";
import daaraConfig from "./daara-config.json";
import { sendHttpGetRequest } from "./axiosInterceptor";
import { isFeatureEnabled } from "@reliance/feature-flags";
import { features } from "./constants";
import apiService, { ApiServiceException } from "./ApiService";
import publicApiService from "./PublicApiService";

class UserService {
  constructor() {
    this.ax = apiService;
    this.publicAx = publicApiService;
  }

  async login(values) {
    try {
      return this.ax.post(`/enrollees/login`, values).then((response) => {
        return mapUser(response.data);
      });
    } catch (error) {
      throw new ApiServiceException(error);
    }
  }

  async auth() {
    const daaraToken = getCookie(DAARA_COOKIE_NAME);
    const refreshToken = getCookie(DAARA_REFRESH_TOKEN);
    const staticToken = getCookie(DOMAIN_COOKIE_NAME);
    const accessToken = isFeatureEnabled(features.jwtToken)
      ? daaraToken
      : staticToken;

    try {
      const response = await this.ax.post(
        `${BASE_URL_RELIANCE}/auth/enrollees`,
        null,
        {}
      );
      let user = mapUser(response);
      user.credentials.accessToken = accessToken;
      user.credentials.refreshToken = refreshToken;
      return user;
    } catch (error) {
      throw new ApiServiceException(error);
    }
  }

  async resetPassword(values) {
    try {
      this.data = querystring.stringify(values);
      await this.ax.post("/accounts/recover-password", this.data);
      return true;
    } catch (error) {
      throw ApiServiceException(error);
    }
  }

  async changePassword(values) {
    try {
      const CHANGE_PASSWORD_URL = `/accounts/change-password${
        values.translate ? "?translated=true" : ""
      }`;
      this.data = values;
      await this.ax.post(CHANGE_PASSWORD_URL, this.data, {});
      return true;
    } catch (error) {
      throw new ApiServiceException(error);
    }
  }

  async getProfile() {
    try {
      const response = await this.ax.get(`/enrollees/profile`, {});
      return response.data;
    } catch (error) {
      throw new ApiServiceException(error);
    }
  }

  async getBenefits() {
    try {
      if (daaraConfig.features.enrollee_service.get_enrollee_benefits) {
        const response = await sendHttpGetRequest(
          `${DAARA_ENROLLEE_BASE_URL}/enrollees/benefits`
        );
        return response.data;
      }
      const response_1 = await this.ax.get(`enrollees/benefits`, {});
      return response_1.data;
    } catch (error) {
      throw new ApiServiceException(error);
    }
  }

  async getSecondaryCareBenefits() {
    try {
      const response = await this.ax.get(`enrollees/secondary-care/all`, {});
      return response.data;
    } catch (error) {
      throw new ApiServiceException(error);
    }
  }

  async requestSecondaryCare(benefitId, providerTypeId, dateTime) {
    try {
      this.data = {
        benefit_id: benefitId,
        provider_id: providerTypeId,
        date_time: dateTime,
      };
      const response = await this.ax.post(
        `enrollees/secondary-care/request`,
        this.data,
        {}
      );
      return {
        paCode: response.data ? response.data.pacode : null,
        message: response.data.message,
      };
    } catch (error) {
      throw new ApiServiceException(error);
    }
  }

  async getPlanDetails() {
    try {
      const response = await this.ax.get(`enrollees/subscription`, {});
      return response.data;
    } catch (error) {
      throw new ApiServiceException(error);
    }
  }

  async renewPlan(invoiceRefCode) {
    try {
      const response = await this.ax.get(`payment/manual/${invoiceRefCode}`);
      return response.data;
    } catch (error) {
      throw new ApiServiceException(error);
    }
  }
  async getUsageData() {
    try {
      const response = await this.ax.get(`enrollees/usage`, {});
      const summary = response.data.summary;
      return {
        tipsRead: summary.total_tips_read,
        questionsAsked: summary.total_questions_asked,
        hospitalVisits: summary.total_hospital_visits,
      };
    } catch (error) {
      throw new ApiServiceException(error);
    }
  }

  async resetPlan(invoiceRefCode) {
    try {
      const response = await this.ax.post(`/retail/renew/${invoiceRefCode}`);
      return response.data;
    } catch (error) {
      throw new ApiServiceException(error);
    }
  }

  async sendReferralCodeToPhoneNumbers(phone_numbers) {
    try {
      const response = await this.ax.post("/enrollees/referral/send-sms", {
        phone_numbers,
      });
      return response.data;
    } catch (error) {
      throw new ApiServiceException(error);
    }
  }

  async getReferrals({ page, status, name }) {
    try {
      const response = await this.ax.get(
        `/enrollees/referral?${querystring.stringify({ page, status, name })}`
      );
      return response.data;
    } catch (error) {
      throw new ApiServiceException(error);
    }
  }

  async getReferralOverview() {
    try {
      const response = await this.ax.get("/enrollees/referral/overview", {});
      return response.data;
    } catch (error) {
      throw new ApiServiceException(error);
    }
  }

  async withdrawCash(amount, bank_id, account_name, account_number) {
    try {
      const response = await this.ax.post("/enrollees/referral/cash-out", {
        amount,
        bank_id,
        account_name,
        account_number,
      });
      return response.data;
    } catch (error) {
      throw new ApiServiceException(error);
    }
  }

  getBanks() {
    try {
      return this.ax.get("/utility/banks").then((response) => {
        return response.data;
      });
    } catch (error) {
      throw new ApiServiceException(error);
    }
  }

  async verifyAccountNumber(bank_id, account_number) {
    try {
      const response = await this.ax.post("/verify/account-number", {
        bank_id,
        account_number,
      });
      return response.data;
    } catch (error) {
      throw new ApiServiceException(error);
    }
  }

  getGovernorates = async (translate) => {
    try {
      const response = await this.publicAx.get(
        `/utility/states?lang=${translate}`
      );
      return response.data;
    } catch (error) {
      // throw new ApiServiceException(error);
    }
  };

  getSpecialties = async (translate) => {
    try {
      const response = await this.ax.get(
        `/utility/specialties?lang=${translate}`
      );
      return response.data;
    } catch (error) {
      throw new ApiServiceException(error);
    }
  };

  unhashParams(hash) {
    try {
      if (hash) {
        this.data = {
          hash: hash,
        };
        return this.publicAx
          .post("/enrollees/unhash", this.data)
          .then((response) => {
            return {
              email_address: response.data.email_address,
              phone_number: response.data.phone_number,
              first_name: response.data.first_name,
              last_name: response.data.last_name,
              is_family_plan: response.data.is_family_plan,
              company_name: response.data.company_name,
              providers: response.data.providers,
            };
          });
      } else {
        return null;
      }
    } catch (error) {
      // throw new ApiServiceException(error);
    }
  }

  async register(values) {
    try {
      //delete profile picture file
      delete values.profile_picture;
      //delete access_token from post body

      const response = await this.ax.post(`/enrollees/profile`, values, {});
      return response.data;
    } catch (error) {
      throw new ApiServiceException(error);
    }
  }
}

function mapUser(json) {
  return {
    id: _.get(json, "data.user_id"),
    hmoId: _.get(json, "data.hmo_id"),
    emailAddress: _.get(json, "data.email_address"),
    firstName: _.get(json, "data.first_name"),
    lastName: _.get(json, "data.last_name"),
    sex: _.get(json, "data.sex"),
    otherNames: _.get(json, "data.other_names"),
    dob: _.get(json, "data.date_of_birth"),
    age: moment().diff(_.get(json, "data.date_of_birth"), "years"),
    package: _.get(json, "data.package"),
    plan: _.get(json, "data.plan"),
    planType: _.get(json, "data.plan_type"),
    userType: _.get(json, "data.user_type"),
    imageUrl: _.get(json, "data.profile_picture"),
    referralUrl: _.get(json, "data.referral_url"),
    isLoggedIn: false,
    credentials: {
      accessToken: _.get(json, "data.access_token"),
      daaraToken: _.get(json, "data.daara_token"),
      fcmToken: "",
      kangpeToken: "",
    },
    preferences: {
      allowPushNotifications: true,
      showCyclePlanner: true,
    },
  };
}

export default new UserService();
