import React from "react";
import { withRouter, Link } from "react-router-dom";
import { reset } from "redux-form";
import { clearUserProfile } from "../actions/user";
import queryString from "querystring";
import LOGO from "../img/logo.svg";
import SignUpForm from "../components/forms/SignUpForm";
import UtilityService from "../services/utilityService";
import LoadingScreen from "../components/LoadingScreen";
import userService from "../services/userService";
import providerService from "../services/providerService";
import { useTranslation, withTranslation } from "react-i18next";
import { FeatureFlagContext } from "../context/FeatureFlagsContext";

class SignUpContainer extends React.Component {
  static contextType = FeatureFlagContext;
  constructor(props) {
    super(props);
    //check match for params, if exists get enrollee type and/or hash
    //check props for params, if exists get enrollee type and accessToken
    const { location } = props;
    const hash = queryString.parse(location.search.substr(1)).hash;

    this.state = {
      isLoading: true,
      formData: {
        user: {
          hash: hash || null,
          accessToken: this.props.location.state
            ? this.props.location.state.at
            : null,
        },
      },
      message: null,
      error: null,
      successful: false,
    };
  }

  componentDidMount() {
    const { user } = this.state.formData;

    Promise.all([
      UtilityService.getUtilities(),
      userService.unhashParams(user.hash),
      providerService.getProviders(user.accessToken),
      userService.getGovernorates(this.determineGovernorateTranslation()),
    ]).then(
      (response) => {
        console.log(response);
        this.setState({
          isLoading: false,
          formData: {
            user: Object.assign({}, user, { ...response[1], enrolleeType: 1 }),
            utils: Object.assign({}, response[0], {
              providers: response[2],
            }),
            governorates: response[3],
          },
          error: null,
        });
      },
      (e) => {
        if (e.response && e.response.status === 403) {
          window.location = "/login";
        } else {
          this.setError({
            message: e.response?.data.message || "An Error Occurred",
            status: e.response?.status,
            code: e.response?.status,
          });
        }
      }
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.lang !== this.props.i18n.language) {
      userService
        .getGovernorates(this.determineGovernorateTranslation())
        .then((response) => {
          this.setState({
            formData: {
              ...this.state.formData,
              governorates: response,
            },
          });
        });

      this.setState({
        lang: this.props.i18n.language,
      });
    }
  }

  determineGovernorateTranslation() {
    return !this.props.i18n.language?.includes("en-US") ? "translated" : "";
  }

  setError = (error, abort) => {
    this.setState({
      isLoading: false,
      abort,
      error,
    });
  };

  scrollToError = () => {
    this.errorHolder.scrollIntoView({ behaviour: "smooth" });
  };

  onSubmitForm = (values, dispatch) => {
    const {
      location: { state },
    } = this.props;

    this.setState({
      isLoading: true,
    });

    if (state && state.subscriptionId) {
      values.subscription_id = state.subscriptionId;
      values.product_id = state.productId;
      values.enrollee_type = 2;
    }

    userService
      .register({
        ...values,
      })
      .then(
        (message) => {
          //reset sign up form on successful submission
          dispatch(reset("signUpForm"));
          dispatch(clearUserProfile());
          this.setState({
            error: null,
            message: message,
            successful: true,
            abort: false,
            isLoading: false,
          });
        },
        (e) => {
          this.setError({
            message: e.response.data.message,
            code: e.status,
            isLoading: false,
          });
          this.scrollToError();
        }
      );
  };

  render() {
    const { isLoading, formData, error, abort, successful } = this.state;
    const translatedErr =
      error && getTranslatedErrorMessage(error.message, this.props.t);
    const flags = this.context.flags;
    const supportEmail = flags.supportEmail;
    const { t } = this.props;

    if (isLoading)
      return (
        <SignUpFormHolder>
          <LoadingScreen />
        </SignUpFormHolder>
      );

    if (!isLoading && abort)
      return (
        <SignUpFormHolder>
          <div
            style={{
              padding: "10px",
              margin: "10px",
              backgroundColor: "red",
              color: "white",
            }}
          >
            <h3>
              {t("signUp_regErrorText")}{" "}
              <a
                style={{ color: "white" }}
                href={`mailto:${supportEmail}?Subject=Enrollee Sign Up Help [from web app]`}
              >
                {t("preSignUp_email")}
              </a>
              .
            </h3>
          </div>
        </SignUpFormHolder>
      );

    if (!isLoading && successful)
      return (
        <SignUpFormHolder>
          <div
            style={{
              padding: "10px",
              margin: "10px",
              backgroundColor: "#fffffa",
              color: "#111",
            }}
          >
            <div>
              <h3>{t("signUp_congratulations")}</h3>
              <p>
                {`${
                  formData.user.accessToken
                    ? t("signUp_yourFamily")
                    : t("signUp_youHave")
                }`}{" "}
                {t("signUp_successMsg")}
                <br />
                <br />
                {t("signUp_successText")}
                <br />
                <br />
                {t("signUp_help")}
                <br />
                {t("signUp_cEmail")}:{" "}
                <a
                  href={`mailto:${supportEmail}?Subject=Enrollee Enquiry [from web app]`}
                >
                  {supportEmail}
                </a>
                <br />
                {t("signUp_cPhone")}:{" "}
                <a href={`tel:${flags.supportPhoneNumber}`}>
                  {flags.supportPhoneNumber}
                </a>
                <br />
                {t("signUp_cWhatsapp")}:{" "}
                <a href={`tel:${flags.supportWhatsAppNumber}`}>
                  {flags.supportWhatsAppNumber}
                </a>
                {flags.featureEnableSocialLinks && flags.supportFacebookUrl && (
                  <>
                    <br />
                    {t("signUp_cFacebook")}:{" "}
                    <a href={`${flags.supportFacebookUrl}`}>
                      {t("preSignUp_facebook")}
                    </a>
                  </>
                )}
                <br />
                <br />
                {t("signUp_thankYou")}
              </p>
              <br />
              <br />
              {formData.user.accessToken ? (
                <div style={{ fontSize: "22px" }}>
                  <div style={{ float: "left" }}>
                    <Link to="/profile">{t("signUp_goBack")}</Link>
                  </div>
                  <div style={{ float: "right" }}>
                    <button
                      className="button_link"
                      onClick={() => window.location.reload()}
                    >
                      {t("signUp_addDependant")}
                    </button>
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    fontSize: "20px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {flags.featureEnableMobileAppDownloads && (
                    <div style={{ marginBottom: "5px" }}>
                      <span>
                        {t("signUp_downloadApp")} (
                        <a href={flags.androidAppDownloadUrl}>Android</a>
                        {" / "}
                        <a href={flags.iosAppDownloadUrl}>IOS</a>)
                      </span>
                    </div>
                  )}
                  {flags.featureShowLoginUrl && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <span>{t("postSignUp_login_to_dashboard")}</span>
                      <a href="/login">{t("signUp_login")}</a>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </SignUpFormHolder>
      );

    return (
      <SignUpFormHolder user={formData.user}>
        {!isLoading && error && (
          <div
            ref={(el) => (this.errorHolder = el)}
            style={{
              padding: "10px",
              margin: "10px",
              backgroundColor: "red",
              color: "white",
              visibility: !isLoading && error ? "visible" : "hidden",
            }}
          >
            <h3>{error.message}</h3>
          </div>
        )}

        <SignUpForm
          data={formData.utils}
          user={formData.user}
          onError={this.setError}
          onSubmit={this.onSubmitForm}
        />
      </SignUpFormHolder>
    );
  }
}

const SignUpContainerWithTranslation = withTranslation(["sign_up"])(
  SignUpContainer
);
export default withRouter(SignUpContainerWithTranslation);

export const SignUpFormHolder = (props) => {
  const { t } = useTranslation(["sign_up"]);

  return (
    <div className="container">
      <div className="signup">
        <div className="signup__header">
          <img src={LOGO} alt="logo" />
          <p>{t("signUp_pageHead")}</p>
        </div>
        <div className="dashboard-card signup__form">{props.children}</div>
      </div>
    </div>
  );
};

function getTranslatedErrorMessage(error, t) {
  if (error.includes("sex")) {
    return t("signUp_genderErr");
  } else if (error.includes("date")) {
    return t("signUp_dobErr");
  } else if (error.includes("picture")) {
    return t("signUp_photoErr");
  } else if (error.includes("governorate")) {
    return t("signUp_governorateErr");
  } else if (error.includes("Token") || error.includes("invoice")) {
    return t("signUp_errorAccessToken");
  } else {
    return `${t("signUp_regErrorText")} ${t("preSignUp_email")}`;
  }
}
