import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Field, reduxForm, formValueSelector, change } from "redux-form";
import ImageInput from "../inputs/ImageInput";
import DateTime from "react-datetime";
import "./datepicker.css";
import moment from "moment";
import "../../services/utilityService";
import UtilityService from "../../services/utilityService";
import LoadingScreen from "../LoadingScreen";
import { useTranslation, withTranslation } from "react-i18next";

class DoctorSignUpForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      lastPage: 3, //can vary depending on the enrollee type,
      isLoadingSection: { profilePicture: false, signaturePhoto: false },
    };
  }

  onNextPage = (e) => {
    e.preventDefault();
    let cPage = this.state.currentPage;
    let nextPage = ++cPage;

    this.props.onError();
    this.props.onError();

    this.setState({
      currentPage: nextPage,
    });
  };

  onPrevPage = () => {
    let cPage = this.state.currentPage;
    let prevPage = --cPage;
    //i.e skip company page, if dependant type is child

    this.setState({
      currentPage: prevPage,
    });
  };

  componentDidMount() {
    const { dispatch, user } = this.props;

    dispatch(change("doctorSignUpForm", "middle_name", user.middle_name));
  }

  onImageUploaded = (img, type) => {
    const { dispatch, user, onError } = this.props;
    //set isLoadingSection in state to true
    //upload img
    //get filename from server
    //change form value
    //set isLoadingSection to false

    const loadingStateTrue =
      type === "profile_picture"
        ? { profilePicture: true }
        : { doctorSignature: true };

    this.setState({
      isLoadingSection: {
        ...this.state.isLoadingSection,
        ...loadingStateTrue,
      },
    });
    this.setState({
      isLoadingSection: {
        ...this.state.isLoadingSection,
        ...loadingStateTrue,
      },
    });

    const loadingStateFalse =
      type === "profile_picture"
        ? { profilePicture: false }
        : { doctorSignature: false };
    const uploadType = type === "profile_picture" ? type : "doctor_signature";

    UtilityService.uploadFile(img, uploadType, user.accessToken)
      .then((response) => {
        dispatch(
          change("doctorSignUpForm", `${type}_filename`, response.filename)
        );
        dispatch(change("doctorSignUpForm", type, img));
        this.setState({
          isLoadingSection: {
            ...this.state.isLoadingSection,
            ...loadingStateFalse,
          },
        });
      })
      .catch((e) => {
        onError({
          message: "Unable to upload picture.",
          code: -1,
        });
        this.setState({
          isLoadingSection: {
            ...this.state.isLoadingSection,
            ...loadingStateFalse,
          },
        });
        console.log("Error uploading image", e);
      });
  };

  onDateSet = (date) => {
    const { dispatch } = this.props;
    let newDate = new Date(date);

    newDate = moment(newDate).locale("en").format("YYYY-MM-DD");
    dispatch(change("doctorSignUpForm", "date_of_birth", newDate));
  };

  render() {
    const {
      error,
      dateOfBirth,
      profilePicture,
      doctorSignature,
      data,
      enrolleeType,
      handleSubmit,
      user,
      pristine,
      doctorSpecialties,
      submitting,
    } = this.props;
    const { currentPage, lastPage, isLoadingSection } = this.state;

    // const { t } = useTranslation(['doctor-signup']);

    return (
      <form
        onSubmit={currentPage !== lastPage ? this.onNextPage : handleSubmit}
        className="form"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "evenly",
          }}
        >
          {[1, 2, 3].map((x) => {
            return <PageIndicator key={x} isActive={x === currentPage} />;
          })}
        </div>
        {currentPage === 1 && (
          <PersonalInfoPage
            user={user}
            data={data}
            onDateSet={this.onDateSet}
            dob={dateOfBirth}
            doctorSpecialties={doctorSpecialties}
          />
        )}
        {currentPage === 2 && <ContactInfoPage user={user} data={data} />}

        {currentPage === 3 && (
          <ExtraInfoPage
            user={user}
            data={data}
            profilePicture={profilePicture}
            doctorSignature={doctorSignature}
            onImageSet={this.onImageUploaded}
            isLoadingPhoto={isLoadingSection}
            enrolleeType={enrolleeType}
          />
        )}

        {error && (
          <div
            style={{ backgroundColor: "red", color: "#FFF", padding: "10px" }}
          >
            {error}
          </div>
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
          }}
        >
          <Field
            component="input"
            type="hidden"
            name="page"
            value={currentPage}
          />
          {currentPage > 1 && (
            <input
              type="button"
              value={this.props.t("previous")}
              onClick={this.onPrevPage}
              style={{ float: "left" }}
            />
          )}

          <div
            style={{ width: "100%", textAlign: "center" }}
          >{`${currentPage} / ${lastPage}`}</div>

          {currentPage >= 1 && currentPage < lastPage && (
            <input
              type="submit"
              value={this.props.t("next")}
              style={{ float: "right" }}
            />
          )}
          {currentPage === lastPage && (
            <input
              type="submit"
              value={this.props.t("submit")}
              disabled={pristine || submitting}
              style={{ float: "right" }}
            />
          )}
        </div>
      </form>
    );
  }
}

const Required = () => (
  <span style={{ color: "red", fontWeight: "bold" }}>*</span>
);

const PersonalInfoPage = (props) => {
  const { t, i18n } = useTranslation(["doctor-signup"]);
  const { user, dob, doctorSpecialties } = props;

  return (
    <div style={{ marginTop: "15px" }}>
      <h2 style={{ fontSize: "24px", color: "#094063" }}>
        {t("personalInformation.header")}
      </h2>
      <p style={{ fontStyle: "italic", fontSize: "10px", color: "#aaa" }}>
        <strong>{t("personalInformation.note")}</strong>
        {t("personalInformation.mark")} (<Required />)
        {t("personalInformation.areRequired")}.
      </p>
      <div style={{ marginTop: "10px" }}>
        <span>
          {t("personalInformation.middleName")} <Required />:
          <Field
            component="input"
            type="text"
            name="middle_name"
            placeholder={t("personalInformation.middleName")}
            readOnly={!!user.middle_name}
            required
          />
          <br />
        </span>
        <span>
          {t("personalInformation.sex")} <Required />:<br />
          <Field name="sex" component="input" type="radio" value="m" />{" "}
          {t("personalInformation.male")}{" "}
          <Field name="sex" component="input" type="radio" value="f" />{" "}
          {t("personalInformation.female")}
          <br />
        </span>
        <span>
          {t("personalInformation.dob")} <Required />:
          <Field
            component="input"
            type="hidden"
            name="date_of_birth"
            placeholder={t("personalInformation.dob")}
            required
          />
          <DateTime
            closeOnSelect
            timeFormat={false}
            dateFormat={t("personalInformation.dateFormat")}
            inputProps={{
              required: true,
              placeholder: t("personalInformation.dateFormat"),
              readOnly: true,
            }}
            onChange={props.onDateSet}
            isValidDate={(currentDate) =>
              currentDate.year() <= DateTime.moment().year() &&
              currentDate.isBefore(new Date())
            }
            value={dob}
            locale={i18n.language}
          />
          <br />
        </span>
        <span>
          {t("personalInformation.position")} <Required />:
          <Field
            component="input"
            name="position"
            type="text"
            placeholder={t("personalInformation.position")}
            required
          />
          <br />
        </span>
        <span>
          {t("personalInformation.specialty")} <Required />
          <Field name="specialty_id" component="select" required>
            <option value="" disabled>
              {t("personalInformation.selectSpecialty")}
            </option>
            {doctorSpecialties?.map((specialty) => {
              return (
                <option key={specialty.id} value={specialty.id}>
                  {specialty.name}
                </option>
              );
            })}
          </Field>
        </span>
        <span>
          {t("personalInformation.mcn")}
          <Required />:
          <Field
            component="input"
            name="mdcn"
            type="text"
            placeholder={t("personalInformation.mcn")}
            required
          />
          <br />
        </span>
      </div>
    </div>
  );
};

const ContactInfoPage = (props) => {
  const { t } = useTranslation(["doctor-signup"]);
  const { user } = props;

  return (
    <div style={{ marginTop: "15px" }}>
      <h2 style={{ fontSize: "24px", color: "#094063" }}>
        {t("contactInfoPage.header")}
      </h2>
      <p style={{ fontStyle: "italic", fontSize: "10px", color: "#aaa" }}>
        <strong>{t("contactInfoPage.note")}</strong>
        {t("contactInfoPage.mark")} (<Required />)
        {t("contactInfoPage.areRequired")}
      </p>
      <div style={{ marginTop: "10px" }}>
        <span>
          {t("contactInfoPage.workPlaceName")}
          <Required />:
          <Field
            component="input"
            name="workplace_name"
            type="phone"
            placeholder={t("contactInfoPage.workPlaceName")}
            readOnly={!!user.workplace_name}
            required
          />
          <br />
        </span>
        <span>
          {t("contactInfoPage.workPlaceAddress")} <Required />:
          <Field
            component="input"
            name="workplace_address"
            type="text"
            placeholder={t("contactInfoPage.workPlaceAddress")}
            required
          />
          <br />
        </span>
      </div>
    </div>
  );
};

const ExtraInfoPage = (props) => {
  const { profilePicture, doctorSignature, onImageSet, isLoadingPhoto } = props;
  const { t } = useTranslation(["doctor-signup"]);

  return (
    <div style={{ marginTop: "15px" }}>
      <h2 style={{ fontSize: "24px", color: "#094063" }}>
        {t("extraInfoPage.header")}
      </h2>
      <p style={{ fontStyle: "italic", fontSize: "10px", color: "#aaa" }}>
        <strong>{t("extraInfoPage.note")}</strong>
        {t("extraInfoPage.mark")} (<Required />){t("extraInfoPage.areRequired")}
      </p>
      <div style={{ marginTop: "10px" }}>
        <Field component="input" type="hidden" name="profile_picture" />
        <Field
          component="input"
          type="hidden"
          name="profile_picture_filename"
        />
        <Field component="input" type="hidden" name="signature" />
        <Field component="input" type="hidden" name="signature_filename" />

        {isLoadingPhoto.profilePicture ? (
          <div style={{ width: "250px", height: "250px" }}>
            <LoadingScreen />
          </div>
        ) : (
          <span>
            {t("extraInfoPage.plsAddPassport")}
            <Required />
            <ImageInput
              width={250}
              height={250}
              onCropComplete={(img) => onImageSet(img, "profile_picture")}
              customClassName="sign__form__file-input"
              placeholder={profilePicture}
            />
          </span>
        )}

        {isLoadingPhoto.doctorSignature ? (
          <div style={{ width: "250px", height: "250px" }}>
            <LoadingScreen />
          </div>
        ) : (
          <span>
            {t("extraInfoPage.plsAddSignature")}
            <Required />
            <ImageInput
              width={250}
              height={250}
              onCropComplete={(img) => onImageSet(img, "signature")}
              customClassName="sign__form__file-input"
              placeholder={doctorSignature}
            />
          </span>
        )}

        <Field component="input" type="hidden" name="hash" />
        <Field component="input" type="hidden" name="access_token" />
      </div>
    </div>
  );
};

const PageIndicator = (props) => {
  return (
    <div
      style={{
        backgroundColor: props.isActive ? "#094063" : "#e6e5e5",
        height: "5px",
        borderRadius: "3px",
        width: "100%",
        margin: "4px",
      }}
    ></div>
  );
};

DoctorSignUpForm = reduxForm({
  form: "doctorSignUpForm",
  destroyOnUnmount: false,
})(DoctorSignUpForm);

// Decorate with connect to read form values
const selector = formValueSelector("doctorSignUpForm");

DoctorSignUpForm = connect((state) => {
  const dateOfBirth = selector(state, "date_of_birth");
  const profilePicture = selector(state, "profile_picture");
  const doctorSignature = selector(state, "signature");

  return {
    dateOfBirth,
    profilePicture,
    doctorSignature,
  };
})(DoctorSignUpForm);

const DoctorSignUpFormWithTranslation = withTranslation(["doctor-signup"])(
  DoctorSignUpForm
);

export default DoctorSignUpFormWithTranslation;
