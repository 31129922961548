import React, { Component } from "react";
import { reduxForm } from "redux-form";
import { ApiService, ENDPOINTS } from "../../services/ApiService";
import MainContent from "../boxes/MainContent";
import PageHeader from "../boxes/PageHeader";
import PageBody from "../boxes/PageBody";
import FilterableMultiSelect from "../inputs/FilterableMultiSelect";
import Modal from "../boxes/Modal";
import PreCheckNewConsultation from "./PreCheckNewConsultationForm";
import LoadingScreen from "../LoadingScreen";
import CallConsultationRequestNotification from "../../components/modals/CallConsultationRequestNotification";
import { withTranslation } from "react-i18next";
import { refreshToken, removeToken } from "../../firebaseInit";
import { Modal as Modalv2 } from "@reliance/design-system";

class PreConsultationForm extends Component {
  constructor(props) {
    super(props);
    this.service = new ApiService(
      props.user.credentials.accessToken,
      props.i18n.language
    );

    this.state = {
      showCallConsultationAppModal: false,
      showNotificationModal: false,
      dependants: [],
      patientId: "",
      mediumId: "1",
      content: "",
      allComplaints: [],
      selectedPrimaryComplaintIDs: null,
      showPreCheckModal: false,
      error: null,
      newConsultationId: null,
    };
  }

  componentDidMount() {
    this.getProfileDetails();
    this.getAllComplaints();
    this.setState({ lang: this.props.i18n.language });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.i18n.language !== this.state.lang) {
      this.getAllComplaints();
      this.setState({ lang: this.props.i18n.language });
    }
  }

  checkNotificationPermission = async () => {
    if (!("Notification" in window)) {
      this.setState({
        notificationMessage: "This browser does not support notifications.",
        showNotificationModal: true,
      });
      return false;
    }

    if (Notification.permission === "denied") {
      this.setState({
        notificationMessage:
          "You have denied notifications. Please enable them in your browser settings.",
        showNotificationModal: true,
      });
      return false;
    }

    if (Notification.permission === "default") {
      const permission = await Notification.requestPermission();
      if (permission !== "granted") {
        this.setState({
          notificationMessage:
            "You need to allow notifications to receive alerts.",
          showNotificationModal: true,
        });
      }
      return permission === "granted";
    }

    return true;
  };

  renderNotificationModal = () => {
    return (
      <Modalv2
        key={"modal"}
        open={this.state.showNotificationModal}
        onOpenChange={() => this.setState({ showNotificationModal: false })}
      >
        <Modalv2.Content
          blank
          style={{ fontSize: 16 }}
          className="w-[40vw] lg:w-[38vw] sm:w-auto max-w-[80%]"
        >
          <p>{this.state.notificationMessage}</p>
        </Modalv2.Content>
      </Modalv2>
    );
  };

  getProfileDetails = () => {
    this.service
      .get(ENDPOINTS.profile.profileDetails)
      .then((response) => {
        this.setState({
          dependants: response.data.dependants,
          phoneNumber: response.data.phone_number,
          address: response.data.address,
        });
      })
      .catch((error) => console.log(error));
  };

  getAllComplaints = () => {
    this.service.get(ENDPOINTS.consultations.complaints).then((response) => {
      this.setState({
        allComplaints: response.data,
      });
    });
  };

  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };

  handleMultiSelectChange = (selectedComplaints) => {
    const complaintIds = selectedComplaints.reduce((a, c) => {
      a.push(c.id);
      return a;
    }, []);

    const reason = selectedComplaints.reduce((a, c, index) => {
      if (index + 1 === selectedComplaints.length) {
        return a + c.name;
      }
      return a + c.name + ", ";
    }, "");

    this.setState({
      selectedPrimaryComplaintIDs: complaintIds,
      content: reason,
    });
  };

  createNewConsultation = async () => {
    const hasPermission = await this.checkNotificationPermission();

    if (hasPermission) {
      await removeToken();
      refreshToken();
    }

    const {
      patientId,
      mediumId,
      content,
      selectedPrimaryComplaintIDs,
      reasons,
    } = this.state;

    const concatenatedCauseOfConsultation =
      content && reasons ? `${content} ${reasons}` : content || reasons;

    const data = {
      medium: mediumId,
      patient_id: patientId,
      reason: concatenatedCauseOfConsultation || null,
      primary_complaints: selectedPrimaryComplaintIDs,
      source: "web",
    };

    this.setState({
      isLoading: true,
      showPreCheckModal: false,
    });

    this.service
      .post(ENDPOINTS.consultations.consultation, data)
      .then((response) => {
        this.setState({ isLoading: false });
        this.setState({ newConsultationId: response.data.consultation_id });
        this.state.mediumId === "2" &&
          this.toggleMobileAppDownloadNotification();
        this.state.mediumId === "1" &&
          this.props.history.push(
            `/consultations/${this.state.newConsultationId}/messages`
          );
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
          error: err.message,
        });
      });
  };

  updateContactAndProceed = (phoneNumber, address) => {
    this.setState({
      isLoading: true,
      showPreCheckModal: false,
    });
    const data = {
      home_phone_number: phoneNumber,
      home_address: address,
    };
    this.service
      .patch(ENDPOINTS.profile.profileDetails, data)
      .then(() => {
        this.createNewConsultation();
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
          error: err.message,
        });
      });
  };

  preCheckNewConsultation = (event) => {
    event.preventDefault();
    this.createNewConsultation();
  };

  toggleMobileAppDownloadNotification = () => {
    this.setState({
      showCallConsultationAppModal: !this.state.showCallConsultationAppModal,
    });
  };

  closeAppDownloadNotification = () => {
    this.setState({ showCallConsultationAppModal: false });
    this.state.newConsultationId &&
      this.props.history.push(
        `/consultations/${this.state.newConsultationId}/messages`
      );
  };

  renderPreCheckModal = () => {
    const { phoneNumber, address } = this.state;
    return (
      <Modal
        title="Please confirm that your phone number and address are up to date"
        onClose={() => this.setState({ showPreCheckModal: false })}
      >
        <PreCheckNewConsultation
          phoneNumber={phoneNumber}
          address={address}
          onContinueConsultation={this.createNewConsultation}
          onUpdateContact={this.updateContactAndProceed}
        />
      </Modal>
    );
  };

  render() {
    const {
      dependants,
      allComplaints,
      showPreCheckModal,
      showNotificationModal,
      error,
    } = this.state;
    const { user } = this.props;
    // const { t } = useTranslation(['consultDoctor']);

    const accountOwner = (
      <option key={user.id} value={user.id}>
        {this.props.t("myself")}
      </option>
    );

    const displayDependants = dependants.map((dependant) => (
      <option
        key={dependant.user_id}
        value={dependant.user_id}
      >{`${dependant.first_name} ${dependant.last_name}`}</option>
    ));

    displayDependants.unshift(accountOwner);

    return (
      <>
        {/* {showPreCheckModal && this.renderPreCheckModal()} */}
        {showNotificationModal && this.renderNotificationModal()}
        <MainContent>
          {this.state.showCallConsultationAppModal &&
            this.state.mediumId === "2" && (
              <CallConsultationRequestNotification
                title={this.props.t("call_consultation_sent")}
                onClose={this.closeAppDownloadNotification}
                isVisible={this.state.showCallConsultationAppModal}
              />
            )}
          <PageHeader title={this.props.t("consult_doctor")} />
          {this.state.isLoading ? (
            <LoadingScreen />
          ) : (
            <PageBody>
              {error && (
                <div className={"page-info-banner error"}>
                  <h3>{error}</h3>
                </div>
              )}
              <div className="dashboard-card">
                <form
                  className="dashboard-form"
                  onSubmit={this.preCheckNewConsultation}
                >
                  <div className="dashboard-form-item-wrapper">
                    <div className="dashboard-input-wrap profile-input-wrap">
                      <span className="dashboard-form-item">
                        <label className="profile-item__label" htmlFor="">
                          {this.props.t(
                            "how_would_you_like_to_consult_a_doctor"
                          )}
                        </label>

                        <select
                          name="mediumId"
                          className="dashboard-form__input dashboard-form__select"
                          onChange={this.handleInputChange}
                          value={this.state.mediumId}
                        >
                          <option value={1}>
                            {this.props.t("chat_doctor")}
                          </option>
                          <option value={2}>
                            {this.props.t("phone_doctor")}
                          </option>
                        </select>
                      </span>

                      <span className="dashboard-form-item">
                        <label className="profile-item__label" htmlFor="">
                          {this.props.t("who_are_you_consulting_for")}
                        </label>
                        <select
                          name="patientId"
                          className="dashboard-form__input dashboard-form__select"
                          onChange={this.handleInputChange}
                          value={this.state.patientId}
                        >
                          {displayDependants}
                        </select>
                      </span>

                      <span className="dashboard-form-item">
                        <label className="profile-item__label" htmlFor="">
                          {this.props.t("select_symptoms")}
                        </label>

                        <FilterableMultiSelect
                          items={allComplaints}
                          filterBy={["name"]}
                          input={{
                            value: [],
                            onChange: this.handleMultiSelectChange,
                          }}
                        />
                      </span>
                      <span className="dashboard-form-item">
                        <label className="profile-item__label" htmlFor="">
                          {this.props.t("additional_symptoms")}
                        </label>
                        <textArea
                          name="reasons"
                          className="dashboard-form__input dashboard-form__input"
                          placeholder={this.props.t(
                            "additional_symptoms_placeholder"
                          )}
                          onChange={this.handleInputChange}
                        />
                      </span>

                      <span className="dashboard-form-item">
                        <h3 className="">{this.props.t("please_note")}</h3>
                        <div
                          className=""
                          style={{
                            color: "black",
                            lineHeight: 1.4,
                            fontWeight: "bold",
                          }}
                        >
                          {this.props.t("disclaimer")}
                        </div>
                      </span>
                    </div>
                  </div>
                  <button
                    className="dashboard__primary-btn dashboard-form__btn"
                    style={{ marginTop: "2rem" }}
                  >
                    {this.props.t("submit")}
                  </button>
                </form>
              </div>
            </PageBody>
          )}
        </MainContent>
      </>
    );
  }
}

PreConsultationForm = reduxForm({
  form: "preConsultationForm",
})(PreConsultationForm);

const PreConsultationFormWithTranslation = withTranslation(["consultDoctor"])(
  PreConsultationForm
);
export default PreConsultationFormWithTranslation;
