import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import queryString from "querystring";
import {
  logoutUser,
  clearUserProfile,
  logoutUserFromDaaraService,
} from "../actions/user";
import LoadingScreen from "../components/LoadingScreen";
import MainContent from "../components/boxes/MainContent";
import { removeToken } from "../firebaseInit";
import Cookies from "js-cookie";

const LogoutContainer = ({ location, logout }) => {
  if (logout) {
    const allCookies = Cookies.get(); // Get all cookies
    Object.keys(allCookies).forEach((cookie) => {
      Cookies.remove(cookie, { path: "/" });
    });

    //check if referred from location
    const from = queryString.parse(location.search.substr(1)).r;
    logout(from);
  }

  return (
    <MainContent>
      <LoadingScreen />
    </MainContent>
  );
};

const mapDispatch = (dispatch) => ({
  logout: (from) => {
    const removeTokenPromise = removeToken();

    removeTokenPromise
      .then(() => {
        dispatch(logoutUser(from));
        dispatch(clearUserProfile());
      })
      .catch((error) => {
        console.log("Error removing token:", error);
      });
  },
});

export default withRouter(connect(null, mapDispatch)(LogoutContainer));
