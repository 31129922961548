import firebase from "firebase/app";
import "firebase/messaging";
import apiService from "./services/ApiService";
import "firebase/analytics";
import userService from "./services/userService";

// p.s this config object is non-secret and safe for public view
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FCM_API_KEY,
  authDomain: process.env.REACT_APP_FCM_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FCM_DATABASE_URL,
  projectId: process.env.REACT_APP_FCM_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FCM_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FCM_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FCM_APP_ID,
};

const initFirebase = () => {
  if (firebase.messaging.isSupported()) {
    firebase.initializeApp(firebaseConfig);
    firebase.analytics();

    const messaging = firebase.messaging();

    const requestNotificationPermission = () =>
      new Promise((resolve, reject) => {
        messaging
          .requestPermission()
          .then(() => messaging.getToken())
          .then((firebaseToken) => {
            resolve(firebaseToken);
          })
          .catch((err) => {
            reject(err);
          });
      });

    requestNotificationPermission()
      .then((firebaseToken) => registerFCMToken(firebaseToken))
      .catch((error) => console.log("FCM Permission Denied:", error));

    // Listen for token refresh events
    messaging.onTokenRefresh(() => {
      refreshToken();
    });
  }
};

const registerFCMToken = (firebaseToken) => {
  return apiService
    .post(`/accounts/fcm-token`, {
      new_fcm_token: firebaseToken,
      type: "Enrollee",
      device: "WEB",
    })
    .then((response) =>
      console.log("FCM Token Registered:", response.data.message)
    )
    .catch((error) => console.log("Error Registering FCM Token:", error));
};

const refreshToken = () => {
  if (firebase.messaging.isSupported()) {
    firebase
      .messaging()
      .getToken()
      .then((newToken) => {
        if (newToken) {
          return registerFCMToken(newToken);
        }
      })
      .catch((error) => console.log("Error Refreshing FCM Token:", error));
  }
};

const removeToken = () => {
  return new Promise((resolve, reject) => {
    if (firebase.messaging.isSupported()) {
      firebase
        .messaging()
        .deleteToken()
        .then(() => {
          resolve();
        })
        .catch((error) => {
          setTimeout(() => {
            reject(error);
          }, 100000);
        });
    } else {
      resolve();
    }
  });
};

const logAnalyticsEvent = async (eventName, data = null) => {
  try {
    let userData = data;

    if (!userData) {
      const { hmo_id, sex, email_address } = await userService.getProfile();
      userData = { hmo_id, sex, email_address };
    }

    firebase.analytics().logEvent(eventName, userData);
  } catch (error) {
    console.error("Error logging Firebase event:", error);
  }
};

export { initFirebase, refreshToken, removeToken, logAnalyticsEvent };
